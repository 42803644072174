import { faTicketAlt } from "@fortawesome/pro-solid-svg-icons"
// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
// page images
import creamPixelsPattern from "assets/img/cream-pixels.png"
// page styles
import ticketsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/ticketsStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"
// react libraries
import Fade from "react-reveal/Fade"
// gatsby libraries
import { Link } from "gatsby"

const useStyles = makeStyles(ticketsStyle)

export default function SectionTickets() {
  const classes = useStyles()
  return (
    <div
      className={classes.section}
      style={{ backgroundImage: `url(${creamPixelsPattern})` }}
      id="tickets"
    >
      <div className={classes.container}>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={2} className={classes.ticketItem}>
            <Fade delay={200}>
              <FontAwesomeIcon
                icon={faTicketAlt}
                className={classes.ticketIcon1}
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={8} className={classes.ticketItem}>
            <Fade delay={800}>
              <Card className={classes.ticketCard} pricing>
                <div
                  className={classes.ticketHoleLeft}
                  style={{ backgroundImage: `url(${creamPixelsPattern})` }}
                ></div>
                <div
                  className={classes.ticketHoleRight}
                  style={{ backgroundImage: `url(${creamPixelsPattern})` }}
                ></div>
                <CardBody>
                  <h3 className={classes.title}>Tickets Coming Soon</h3>
                  {/* <p className={classes.description}>
                    Click below to purchase your tickets early at special
                    pre-sale prices for Chilimania weekend! Chilimania and
                    Country Edge tickets are also available for purchase at the
                    gate.
                  </p> */}
                  <Link to="/admissions">
                    <Button size="sm" color="secondary">
                      More Admissions Info
                    </Button>
                  </Link>
                  <hr className={classes.ticketDivider} />
                  <p className={classes.description}>
                    Country Edge and Chilimania are separate events and require
                    separate admission tickets.
                  </p>
                </CardBody>
                <CardFooter className={classes.ticketFooter}>
                  {/* <Button
                    className={classes.ticketButton}
                    href="https://chilimania.ticketspice.com/country-edge-2024"
                    color="primary"
                    target="_self"
                    rel="next"
                  >
                    Country Edge
                    <ConfirmationNumberIcon />
                  </Button>
                  <Button
                    className={classes.ticketButton}
                    href="https://chilimania.ticketspice.com/chilimania-2024"
                    color="primary"
                    target="_self"
                    rel="next"
                  >
                    Chilimania
                    <ConfirmationNumberIcon />
                  </Button> */}
                </CardFooter>
              </Card>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={2} className={classes.ticketItem}>
            <Fade delay={500}>
              <FontAwesomeIcon
                icon={faTicketAlt}
                className={classes.ticketIcon2}
              />
            </Fade>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
