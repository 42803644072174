import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// react libraries
import Fade from "react-reveal/Fade"

// helpers
import moment from "moment"
import { getEventDate } from "helpers/Functions.jsx"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import EventIcon from "@material-ui/icons/Event"
import LocationOnIcon from "@material-ui/icons/LocationOn"

// core components
import Button from "components/CustomButtons/Button.jsx"
import CustomFooter from "components/Footer/CustomFooter.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"

// page sections
import SectionAbout from "./Sections/SectionAbout.jsx"
import SectionAdmission from "./Sections/SectionAdmission.jsx"
import SectionBlog from "./Sections/SectionBlog.jsx"
import SectionCookOff from "./Sections/SectionCookOff.jsx"
import SectionEventInfo from "./Sections/SectionEventInfo.jsx"
import SectionHeadliner from "./Sections/SectionHeadliner.jsx"
import SectionTimer from "./Sections/SectionTimer.jsx"
import SectionParticipation from "./Sections/SectionParticipation.jsx"
import SectionSponsor from "./Sections/SectionSponsor.jsx"
import SectionTickets from "./Sections/SectionTickets.jsx"

// page styles
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx"
import { Info, ShoppingCart } from "@material-ui/icons"
const useStyles = makeStyles(landingPageStyle)

const countryEdgeDate = getEventDate("countryEdge")
const chilimaniaDate = getEventDate("chilimania")
console.log(countryEdgeDate)
console.log(chilimaniaDate)

export default function LandingPage() {
  const { audienceBackground, chiliDude } = useStaticQuery(graphql`
    query getLandingPageImages {
      audienceBackground: file(
        relativePath: { eq: "banners/concert-audience.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      chiliDude: file(relativePath: { eq: "logos/chili-dude.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div id="landingPage">
      <SEOHeader title="Chilimania - Chili | Beer | Bands | Fun" />
      <CustomHeader />
      <ReactParallax
        imageData={audienceBackground.childImageSharp.gatsbyImageData}
        imageTitle={"Admissions Banner"}
        imageStyle={{ height: "100vh" }}
        filter="dark"
        className={classes.headerParallax}
        alignItems={"center"}
      >
        <div className={classes.bannerContainer}>
          <GridContainer className={classes.bannerRow}>
            <GridItem xs={12} sm={12} md={6} className={classes.welcomeMessage}>
              <div className={classes.tagline}>
                <Fade top>
                  <h2 className={classes.bannerTitle}>CHILI.</h2>
                </Fade>
                <Fade top delay={500}>
                  <h2 className={classes.bannerTitle}>BEER.</h2>
                </Fade>
                <Fade top delay={1000}>
                  <h2 className={classes.bannerTitle}>BANDS.</h2>
                </Fade>
                <Fade top delay={1500}>
                  <h2 className={classes.bannerTitle}>FUN.</h2>
                </Fade>
              </div>
              <h3 className={classes.message}>
                <Fade bottom delay={1500}>
                  <i>Celebrating Charity Through Music &amp; Chili</i>
                </Fade>
              </h3>
              {/* <Fade bottom delay={1500}>
                <AnchorLink to="#admission">
                  <Button color="primary">Buy Tickets Now</Button>
                </AnchorLink>
              </Fade> */}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <GridContainer>
                <GridItem xs={6} sm={6} md={12} className={classes.date}>
                  <Fade right delay={2000}>
                    <EventIcon className={classes.iconLarge} />
                    <h3 className={classes.bannerHeader}>When</h3>
                    <h4 className={classes.bannerInfo}>
                      {moment(countryEdgeDate).format("ddd, MMMM D")} <br />
                      &amp; {moment(chilimaniaDate).format("ddd, MMMM D")}
                    </h4>
                  </Fade>
                </GridItem>
                <GridItem xs={6} sm={6} md={12} className={classes.location}>
                  <Fade right delay={2500}>
                    <LocationOnIcon className={classes.iconLarge} />
                    <h3 className={classes.bannerHeader}>Where</h3>
                    <h4 className={classes.bannerInfo}>Edgerton, WI</h4>
                  </Fade>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <div className={classes.chiliDudeContainer}>
          <Fade up delay={3000}>
            <GatsbyImage
              image={chiliDude.childImageSharp.gatsbyImageData}
              className={classes.chiliDude}
              alt="Chili Dude"
            />
          </Fade>
        </div>
        <SectionAbout />
        <SectionHeadliner />
        <SectionAdmission />
        <SectionTickets />
        <SectionCookOff />
        <SectionSponsor />
        <SectionParticipation />
        <SectionEventInfo />
        <SectionBlog />
        <SectionTimer />
        <CustomFooter />
      </div>
    </div>
  )
}
